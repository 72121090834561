import api from '../../utils/api'

// 登录
export async function login (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: '张三',
  //         token: 'eyJ1c2VyX2lkIjoxLCJleHBpcmVfdGltZSI6MTYxMTY1MDc5ODUxMn0.sOU12Rv6lTloal_pTTp_oWJ_QpE'
  //       }
  //     })
  //   }, 500)
  // })
  return api.post('/api/v1/mp/users/session', payload)
}

// 退出
export async function add_address (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post('/api/v1/mp/address/add', payload)
}

export async function delete_i (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/address/delete/${payload}`)
}
