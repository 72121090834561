<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <van-form @submit="onSubmit" validate-first>
        <div class="out">
          <van-field
            v-model="data.recipient"
            label="收件人"
            label-class="title"
            name="recipient"
            clearable
            maxlength="20"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
            v-model="data.phone"
            type="tel"
            label="手机号"
            label-class="title"
            name="pattern"
            clearable
            :rules="[{ pattern, message: '请输入正确的手机号' }]"
          />
          <van-field
            v-model="data.address"
            rows="2"
            autosize
            type="textarea"
            label="收货地址"
            name="address"
            label-class="title"
            right-icon="arrow"
            maxlength="200"
            @click-right-icon="right_click"
            :rules="[{ required: true, message: '请填写收货地址' }]"
            
          />
          <van-field
            v-model="data.house_num"
            label="门牌号"
            name="house_num"
            label-class="title"
            clearable
            maxlength="50"
            :rules="[{ required: true, message: '请填写门牌号' }]"
            
          />
          <div class="line">
            <div class="left">
              <div class="title">设置默认地址</div>
            </div>
            <div class="right">
              <van-switch
                v-model="data.default_address"
                name="default_address"
                size="22px"
              />
            </div>
          </div>
        </div>
        <button class="add_btn" type="submit">{{data.id ? '编辑':'新增'}}</button>
        
      </van-form>
      <div class="delete_btn" @click="confirm_delete">删除</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {add_address, delete_i} from './service'

export default {
  data() {
    return {
      loading: false,
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    };
  },
  computed: {
    ...mapState("locationData", {
      data: (state) => state.location,
    }),
  },
  components: {},
  created() {
    this.address = this.$route.query.address
    if(this.address){
      this.data.address = this.address
    }
  },
  methods: {
    ...mapMutations("locationData", ["set_location"]),
    right_click() {
      this.$router.push({ path: "/edit-map", query: { address: this.data.address, ...this.$route.query } });
    },
    onSubmit() {
      this.edit_address()
    },
    async edit_address() {
      this.loading = true
      try {
        await add_address(this.data)
        this.$router.push({path: '/my-location', query: {...this.$route.query}})
      }finally {
        this.loading = false
      }
    },
    confirm_delete(){
      this.$dialog.confirm({
        message: '确认删除当前地址？',
        confirmButtonColor: "#0088FF",
      })
        .then(() => {
          this.delete_address()
        })
        .catch(() => {
          // on cancel
        });
    },
    async delete_address(){
      if(!this.data.id) return
      this.loading = true
      try {
        await delete_i(this.data.id)
        this.$router.push({path: '/my-location', query: {...this.$route.query}})
      }finally {
        this.loading = false
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.g_page_box {
  background: #f2f7f8;
}

.out {
  background: #fff;
}

>>> .title {
  color: #323233;
}

.line {
  padding: 10px 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.add_btn,
.delete_btn {
  width: calc(100% - 32px);
  display: block;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 36px;
  margin: 30px 16px -15px;
  text-align: center;
  border-radius: 18px;
}

.add_btn {
  color: #fff;
  background: #1890ff;
}

.delete_btn {
  color: #0088ff;
  background: #e5f3ff;
  border: 1px solid #0985fa;
}
</style>
